export default (client, defaultParams) => ({
  create: (payload) =>
    client('/admin/subscription', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  getForUser: (id) =>
    client(`/admin/subscriptions/user/${id}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  get: (id) =>
    client(`/admin/subscription/${id}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  find: (payload) =>
    client('/admin/subscriptions/find', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  setStatus: (id, status) =>
    client(`/admin/subscription/${id}/status/${status}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  setQty: (id, qty) =>
    client(`/admin/subscription/${id}/qty/${qty}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  update: (id, payload) =>
    client(`/admin/subscription/${id}/update`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  checkSubscription: (id) =>
    client(`/admin/subscription/${id}/check`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  addReceipt: (id, payload) =>
    client(`/admin/renewal/${id}/add_receipt`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  addItem: (id, payload) =>
    client(`/admin/subscription/${id}/add_item`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  addDocument: (id, payload) =>
    client(`/admin/subscription/${id}/add_document`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  changePlan: (id, payload) =>
    client(`/admin/subscription/${id}/change_plan`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  changeEndDate: (id, endDate) =>
    client(`/admin/subscription/${id}/change_end_date`, {
      method: 'PUT',
      data: {end_date: endDate},
      params: {
        ...defaultParams,
      },
    }),
  checkReferralCode: (userId, code) =>
    client(`/referrals/validate/${userId}/${code}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  validateRenewal: (id) =>
    client(`/admin/renewal/${id}/validate_payment`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  updateRenewalDiscount: (id, data) =>
    client(`/admin/renewal/${id}/set_discount`, {
      method: 'PUT',
      data,
      params: {
        ...defaultParams,
      },
    }),
  setMultipleStatus: (ids, status, data) =>
    client('/admin/subscriptions/multiple_set_status', {
      method: 'PUT',
      data: {
        ids,
        status,
        data,
      },
      params: {
        ...defaultParams,
      },
    }),
  setItemStatus: (id, status, data = {}) =>
    client(`/admin/subscription_item/${id}/status/${status.toUpperCase()}`, {
      method: 'PUT',
      data,
      params: {
        ...defaultParams,
      },
    }),
});

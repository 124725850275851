import apiClient from '../api';
import {executeAndWaitResponse} from './service';

export const create = async (payload) =>
  executeAndWaitResponse(() => apiClient.subscriptions.create(payload));
export const getForUser = async (id) =>
  executeAndWaitResponse(() => apiClient.subscriptions.getForUser(id));
export const get = async (id) =>
  executeAndWaitResponse(() => apiClient.subscriptions.get(id));
export const find = async (payload) =>
  executeAndWaitResponse(() => apiClient.subscriptions.find(payload));
export const setStatus = async (subscriptionId, status) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.setStatus(subscriptionId, status)
  );
export const setQty = async (subscriptionId, qty) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.setQty(subscriptionId, qty)
  );
export const addReceipt = async (renewalId, payload) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.addReceipt(renewalId, payload)
  );
export const validateRenewal = async (renewalId) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.validateRenewal(renewalId)
  );
export const setMultipleStatus = async (ids, status, data) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.setMultipleStatus(ids, status, data)
  );
export const setItemStatus = async (id, status, data) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.setItemStatus(id, status, data)
  );
export const addItem = async (id, payload) =>
  executeAndWaitResponse(() => apiClient.subscriptions.addItem(id, payload));
export const changePlan = async (id, payload) =>
  executeAndWaitResponse(() => apiClient.subscriptions.changePlan(id, payload));
export const changeEndDate = async (id, endDate) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.changeEndDate(id, endDate)
  );
export const checkSubscription = async (id) =>
  executeAndWaitResponse(() => apiClient.subscriptions.checkSubscription(id));

export const updateRenewalDiscount = async (id, amount, reason) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.updateRenewalDiscount(id, {amount, reason})
  );

export const addDocument = async (id, document) =>
  executeAndWaitResponse(() =>
    apiClient.subscriptions.addDocument(id, {document})
  );

export default {
  create,
  getForUser,
  find,
  setStatus,
  setQty,
  addItem,
  addReceipt,
  validateRenewal,
  setMultipleStatus,
  setItemStatus,
  changePlan,
  changeEndDate,
  addDocument,
};
